const useLocalConfig = true;
const host = 'growing.faith';

// Defines is the local config should be used
const environments = ['localhost', 'vscode.dev', 'code-server.growing-faith.dev', 'devtunnel', '192.168.1.15'];

// Don't change anything below this line

const isDevEnvironment = () => environments.reduce((isDevEnv, env) => isDevEnv || window.location.hostname.includes(env), false);

export default {
    isDevEnvironment,
    useLocalConfig,
    host
};
